var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 800,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
        cancelText: "关闭",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form } },
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "用户名",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["userName", {}],
                        expression: "['userName', {}]",
                      },
                    ],
                    attrs: { placeholder: "请输入用户名", readOnly: "" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "代理人用户名",
                  },
                },
                [
                  _c("j-select-user-by-dep", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "agentUserName",
                          _vm.validatorRules.agentUserName,
                        ],
                        expression:
                          "['agentUserName', validatorRules.agentUserName]",
                      },
                    ],
                    attrs: {
                      placeholder: "请输入代理人用户名",
                      "trigger-change": true,
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "代理开始时间",
                  },
                },
                [
                  _c("j-date", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["startTime", _vm.validatorRules.startTime],
                        expression: "[ 'startTime', validatorRules.startTime]",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      "trigger-change": true,
                      showTime: true,
                      "date-format": "YYYY-MM-DD HH:mm:ss",
                      placeholder: "请选择开始时间",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "代理结束时间",
                  },
                },
                [
                  _c("j-date", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["endTime", _vm.validatorRules.endTime],
                        expression: "[ 'endTime', validatorRules.endTime]",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      "trigger-change": true,
                      showTime: true,
                      "date-format": "YYYY-MM-DD HH:mm:ss",
                      placeholder: "请选择结束时间",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "状态",
                  },
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: ["status", {}],
                          expression: "[ 'status', {}]",
                        },
                      ],
                      staticClass: "fontiframe",
                      attrs: { name: "radioGroup" },
                    },
                    [
                      _c(
                        "a-radio",
                        { staticClass: "radioGroup", attrs: { value: "1" } },
                        [_vm._v("有效")]
                      ),
                      _c(
                        "a-radio",
                        { staticClass: "radioGroup", attrs: { value: "0" } },
                        [_vm._v("无效")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }